<script>
import Layout from '@layouts/main'
import justificationModal from '@views/justification/justificationModal'
import justificationFilterModal from '@views/justification/justificationFilterModal'
import swalFeedbackVue from '@src/mixins/swalFeedback.vue'
import JustificationService from '@src/services/JustificationService'
import ApiModeVuetable from '@src/components/ApiModeVuetable'
import Breadcrumb from '@/src/components/Breadcrumb.vue'
import PageHeader from '@/src/components/PageHeader.vue'
import justificationFields from '@src/fields/justificationFields'
import ExportButton from '@/src/components/ExportButton.vue'
import filterVuetable from '@src/mixins/filterVuetable'
import FilterLabelButton from '@/src/components/FilterLabelButton.vue'
import MoreTableButton from '@/src/components/MoreTableButton.vue'

const i18nCommon = 'COMMON'
const i18nKey = 'JUSTIFICATION'

export default {
    components: {
        Layout,
        justificationModal,
        justificationFilterModal,
        ApiModeVuetable,
        Breadcrumb,
        PageHeader,
        ExportButton,
        FilterLabelButton,
        MoreTableButton
    },
    mixins: [swalFeedbackVue, justificationFields, filterVuetable],
    data() {
        return {
            sortOrder: [
                {
                    field: 'justification_date',
                    direction: 'desc',
                },
            ],
            i18nCommon: i18nCommon,
            i18nKey: i18nKey,
            modalIsEdit: false,
            modal: {
                id: 0,
                business_unit_id: 0,
                businessUnit: {
                    id: 0,
                    name: '',
                },
                justification_type_id: 0,
                justificationType: {
                    id: 0,
                    name: '',
                },
                work_schedule_id: 0,
                pointOfSale: {
                    id: 0,
                    name: ''
                },
                user_id: 0,
                user: {
                    id: 0,
                    name: ''
                },
                justification: '',
                justification_date: null,
                status: false,
            },
            modalHasResponseError: false,
            additionalParameters: {
                with: [
                    'user',
                    'justificationType',
                    'file',
                    'pointOfSale',
                    'businessUnit',
                ],
            },
            loadingEdit: [],
            filters: {
                justificationDate: null,
                user: [],
                business_unit: [],
                point_of_sale: [],
                justification_type: [],
            },
        }
    },
    computed: {
        items() {
            return [
                {
                    text: 'Home',
                    href: '/',
                },
                {
                    text: this.getI18nModified({
                        prefix: i18nKey,
                        suffix: 'TITLES.justification',
                        modifier: 2,
                    }),
                    active: true,
                },
                {
                    text: this.getI18nModified({
                        prefix: i18nKey,
                        suffix: 'TITLES.justification',
                        modifier: 2,
                    }),
                    active: true,
                },
            ]
        },
        i18nName() {
            return this.getI18n(i18nCommon, 'name')
        },
        i18nNewJustification() {
            return this.getI18n(i18nKey, 'BUTTONS.new_justification')
        },
        i18nBusinessUnit() {
            return this.getI18n(i18nCommon, 'business_unit')
        },
        i18nPos() {
            return this.getI18n('POS', 'TITLES.pos')
        },
        i18nUser() {
            return this.getI18n('USERS', 'TITLES.user')
        },
        i18nJustificationType() {
            return this.getI18n('JUSTIFICATION', 'TITLES.justification_type')
        },
    },
    methods: {
        i18nJustification(modifier) {
            return this.getI18nModified({
                prefix: 'JUSTIFICATION.TITLES',
                suffix: 'justification',
                modifier: modifier,
            })
        },

        refresh() {
            this.$nextTick(() => {
                this.$refs.vuetable.refresh()
            })
        },

        fetch(url, params) {
            return JustificationService.fetchVuetable(url, params)
        },

        createAdditionalParameters() {
            return this.formatParameters({
                with: [
                    'user',
                    'justificationType',
                    'file',
                    'pointOfSale',
                    'businessUnit',
                ],
                user_id: this.filters.user.map((unit) => unit.id),
                business_unit_id: this.filters.business_unit.map((unit) => unit.id),
                justification_type_id: this.filters.justification_type.map((unit) => unit.id),
                point_of_sale_id: this.filters.point_of_sale.map((pos) => pos.id),
                justification_date: this.formatFilterDate(this.filters.justificationDate),
            })
        },

        filter(appliedFilters) {
            this.filters = appliedFilters;
            this.additionalParameters = this.createAdditionalParameters()
            this.refreshTable()
        },

        showFilterModal() {
            this.$nextTick(() => this.$refs.justificationFilterModal.showModal(this.filters))
        },

        showModal() {
            this.$nextTick(() => this.$refs.justificationModal.showModal())
        },

        async openModalToEdit(data, index, event) {
            this.loadingEdit.push(data.id)
            this.modalIsEdit = true
            this.replaceModalData(data)
            this.showModal()
            this.loadingEdit.splice(
                this.loadingEdit.findIndex((el) => el === data.id),
                1
            )
        },

        replaceModalData(data) {
            this.modal = Object.assign(this.modal, data)
        },

        reloadTable() {
            this.$refs.vuetable.reload()
        },

        refreshTable() {
            this.$refs.vuetable.refresh()
        },
    },
}
</script>

<template>
    <Layout>
        <div class="col-12">
            <Breadcrumb :items="items" />
            <div class="row mt-3">
                <div class="col-sm-6 mb-3 form-inline"></div>
                <div class="col-sm-6 mb-3">
                    <div class="text-sm-right">
                        <filter-label-button @onClick="showFilterModal" />
                        <export-button
                            v-if="$can('CREATE', 'EXPORTJOB')"
                            type="justification"
                            :create-parameters="createAdditionalParameters"
                        />
                    </div>
                </div>
            </div>
            <div class="card card-pdv">
                <PageHeader :title="i18nJustification(2)" />
                <div class="card-body">
                    <api-mode-vuetable
                        ref="vuetable"
                        :fetch-data="fetch"
                        :display-selection-records-per-page="true"
                        :additional-parameters="additionalParameters"
                        :fields="justificationFields"
                        :sort-order="sortOrder">
                        <template slot="actions" slot-scope="props">
                            <div class="table-button-container">
                                <more-table-button @onClick="openModalToEdit(props.rowData, props.rowIndex, $event)" />
                            </div>
                        </template>
                    </api-mode-vuetable>
                </div>
            </div>
        </div>
        <justification-modal
            ref="justificationModal"
            :modal="modal"
            @refresh="reloadTable"
        />
        <justification-filter-modal
            ref="justificationFilterModal"
            :handle-submit="filter"
        />
    </Layout>
</template>
